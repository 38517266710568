@import "variable";
@import url("slick-carousel/slick/slick.css");
@import url("slick-carousel/slick/slick-theme.css");
@import url("react-toastify/dist/ReactToastify.css");
@import url("aos/dist/aos.css");

@font-face {
  font-family: 'Bangers';
  src: url('./assets/fonts/Bangers-Regular.eot');
  src: url('./assets/fonts/Bangers-Regular.eot?#iefix') format('embedded-opentype'), url('./assets/fonts/Bangers-Regular.woff2') format('woff2'), url('./assets/fonts/Bangers-Regular.woff') format('woff'), url('./assets/fonts/Bangers-Regular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Silkscreen';
  src: url('./assets/fonts/Silkscreen.eot');
  src: url('./assets/fonts/Silkscreen.eot?#iefix') format('embedded-opentype'), url('./assets/fonts/Silkscreen.woff2') format('woff2'), url('./assets/fonts/Silkscreen.woff') format('woff'), url('./assets/fonts/Silkscreen.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Barlow';
  src: url('./assets/fonts/Barlow-Regular.eot');
  src: url('./assets/fonts/Barlow-Regular.eot?#iefix') format('embedded-opentype'), url('./assets/fonts/Barlow-Regular.woff2') format('woff2'), url('./assets/fonts/Barlow-Regular.woff') format('woff'), url('./assets/fonts/Barlow-Regular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

$orange: #FF9700;
$dark: #161616;
$font_bangers: 'Bangers';
$font_silkscreen: 'Silkscreen';
$font_barlow: 'Barlow';

a {
  color: #000;
  text-decoration: none;

  &:hover {
    color: #000;
  }
}

.font_bangers {
  font-family: $font_bangers;
}

.font_silkscreen {
  font-family: $font_silkscreen;
}

.font_barlow {
  font-family: $font_barlow;
}

body {
  font-family: $font_barlow;
  overflow-x: hidden;
  background: #FFF0D7 url("./assets/images/hero-bg-1.png") no-repeat left top;
  background-position: -550px -665px;

  @media screen and (max-width: 991px) {
    background-position: -600px -540px;
  }
}

.fs_16px {
  font-size: 16px;
}

.fs_18px {
  font-size: 18px;
}

.fs_20px {
  font-size: 20px;
}

.fs_22px {
  font-size: 22px;
}

.fw_400 {
  font-weight: 400;
}

.fw_500 {
  font-weight: 500;
}

.fw_600 {
  font-weight: 600;
}

.color_orange {
  color: $orange;
}

.color_dark {
  color: $dark;
}

.btn_white {
  min-width: 120px;
  font-size: 16px;
  line-height: 20px;
  color: $dark;
  font-family: $font_silkscreen;
  text-transform: uppercase;
  background: #fff;
  border: none;
  padding: 15px 22px;
  @include border-radius(0);

  // &:hover {
  //   background: transparent linear-gradient(108deg, #48A7E2 0%, #C95ABD 49%, #FEC740 100%) 0% 0% no-repeat padding-box;
  // }
  @media screen and (max-width: 991px) {
    padding: 10px;
  }
}

.btn_black {
  font-size: 16px;
  line-height: 20px;
  color: #fff;
  font-family: $font_silkscreen;
  text-transform: uppercase;
  background: #000;
  border: none;
  padding: 15px 22px;
  @include border-radius(0);

  @media screen and (max-width: 991px) {
    padding: 10px;
  }
}

.btn_border {
  font-size: 16px;
  line-height: 20px;
  color: $dark;
  font-family: $font_silkscreen;
  text-transform: uppercase;
  border: 1px solid #707070;
  padding: 15px 28px;
  color: #000;
  background: transparent;
  text-transform: uppercase;
  @include border-radius(10px);

  &.active,
  &:hover {
    background: transparent linear-gradient(127deg, #FFF0DF 0%, #FFE7D7 52%, #FFDFCF 100%) 0 0 no-repeat padding-box;
    border: 1px solid transparent;
  }
}

.btn_custom {
  font-size: 16px;
  line-height: 20px;
  color: $dark;
  font-family: $font_silkscreen;
  text-transform: uppercase;
  border: none;
  padding: 15px 28px;
  color: #fff;
  text-transform: uppercase;
  @include border-radius(10px);

  &.orange {
    background: $orange;
  }

  &.pink {
    background: #FF649B;
  }

  &.skyblue {
    background: #59B3D6;
  }

  &.green {
    background: #49BF4D;
  }

  &.red {
    background: #FF3D3D;
  }

  @media screen and (max-width: 991px) {
    padding: 10px 15px;
  }
}

.container {
  @media screen and (min-width: 1600px) {
    max-width: 1600px;
  }
}

header {
  margin-top: 25px;
  margin-bottom: 145px;

  @media screen and (max-width: 1440px) {
    margin-bottom: 100px;
  }

  @media screen and (max-width: 991px) {
    margin-bottom: 50px;
  }

  .container {
    display: flex;
    align-items: center;
    justify-content: space-between;

    .logo {
      img {
        @media screen and (max-width: 991px) {
          width: 80%;
        }
      }
    }

    .navbar {
      .navbar-light .navbar-toggler {
        border: none;
      }
    }

    .navbar-nav {
      a.nav-link {
        font-family: $font_silkscreen;
        font-size: 16px;
        color: #000;
        text-transform: uppercase;
        margin-right: 50px;
        display: flex;
        align-items: center;

        &.active {
          &::before {
            content: "";
            position: absolute;
            border-bottom: 1px solid #000D0D;
          }
        }

        @media screen and (max-width: 1600px) {
          margin-right: 20px;
        }
      }
    }
  }
}

h1 {
  font-size: 75px;
  line-height: 90px;
  color: $dark;
  font-family: $font_bangers;
  text-transform: uppercase;

  @media screen and (max-width: 991px) {
    font-size: 40px;
    line-height: 1.2;
  }
}

h2 {
  font-size: 55px;
  line-height: 65px;
  color: $dark;
  font-family: $font_bangers;
  text-transform: uppercase;

  @media screen and (max-width: 1440px) {
    font-size: 45px;
    line-height: 1.2;
  }

  @media screen and (max-width: 991px) {
    font-size: 35px;
  }
}

h3 {
  font-size: 45px;
  line-height: 50px;
  color: $dark;
  font-family: $font_bangers;
  text-transform: uppercase;

  @media screen and (max-width: 1440px) {
    font-size: 35px;
    line-height: 1.2;
  }

  @media screen and (max-width: 991px) {
    font-size: 30px;
  }
}

h4 {
  font-size: 32px;
  line-height: 42px;
  color: $dark;
  font-family: $font_bangers;
  text-transform: uppercase;

  @media screen and (max-width: 991px) {
    font-size: 25px;
    line-height: 1.2;
  }
}

.form_group label,
p {
  font-size: 25px;
  line-height: 36px;
  color: #000;
  font-family: $font_barlow;

  @media screen and (max-width: 1440px) {
    font-size: 20px;
    line-height: 1.5;
  }

  @media screen and (max-width: 991px) {
    font-size: 18px;
    line-height: 1.5;
  }
}

.homepage {
  .hero_banner {
    padding-top: 50px;
    padding-bottom: 200px;

    background: url("./assets/images/hero-bg-2.svg") no-repeat;
    background-position: 100% 120px;

    @media screen and (max-width: 1440px) {
      padding-top: 35px;
      padding-bottom: 160px;
    }

    @media screen and (max-width: 991px) {
      padding-top: 20px;
      padding-bottom: 130px;
      background-position: 190% 590px;
      background-size: 60%;
    }

    .hero_content_wrap {
      display: flex;
      align-items: center;

      .hero_content {
        .p1 {
          font-size: 75px;
          line-height: 90px;
          color: $orange;
          font-family: $font_bangers;
          margin-bottom: 0;

          @media screen and (max-width: 991px) {
            font-size: 40px;
            line-height: 1.2;
          }
        }

        .p2 {
          font-size: 25px;
          line-height: 36px;
          color: $dark;
          font-family: $font_silkscreen;
          margin: 25px 0;
          text-transform: uppercase;

          @media screen and (max-width: 991px) {
            font-size: 20px;
            margin: 15px 0;
          }

        }

        .p3 {
          font-size: 25px;
          line-height: 36px;
          color: $dark;
          font-family: $font_barlow;
          margin-top: 25px;
          margin-bottom: 0;

          @media screen and (max-width: 991px) {
            font-size: 20px;
            margin-top: 10px;
          }
        }
      }
    }

    .hero_media_wrap {
      display: flex;
      justify-content: flex-end;
      position: relative;

      .hero_media {
        &:hover .dog_spces {
          transform: translate(-105px, -95px);
        }

        img {
          flex: none;
        }

        .dog_spces {
          position: absolute;
          left: 65%;
          top: 51%;
          transition: all .3s ease-in-out;
          transform: translate(-31%, -111%);
        }

        @media screen and (max-width: 991px) {
          width: 100%;
          display: flex;
          justify-content: center;
          margin-top: 80px;
        }
      }
    }
  }

  .trust_section {
    h2 {
      margin-bottom: 70px;
    }

    .trust_block {
      margin-bottom: 50px;

      .icon {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 105px;
        height: 105px;
        background: transparent linear-gradient(135deg, #FFF0DF 0%, #FFDFCF 100%) 0 0 no-repeat padding-box;
        border-radius: 20px;

        img {
          @media screen and (max-width: 1440px) {
            width: 50%;
            height: 50%;
          }
        }

        @media screen and (max-width: 1440px) {
          width: 80px;
          height: 80px;
          border-radius: 10px;
        }

        @media screen and (max-width: 991px) {
          width: 75px;
          height: 75px;
        }
      }

      h4 {
        margin: 30px 0 20px;
      }

      p {
        @media screen and (min-width: 992px) {
          padding-right: 100px;
        }
      }

    }
  }

  .how_do_get_section {
    margin-top: 120px;

    @media screen and (max-width: 1440px) {
      margin-top: 100px;
    }

    @media screen and (max-width: 991px) {
      margin-top: 50px;
    }

    h2 {
      margin-bottom: 70px;
    }

    .hdg_block {
      display: flex;
      flex-direction: column;
      height: 600px;
      padding: 70px;
      justify-content: flex-end;
      background: transparent linear-gradient(135deg, #FFF0D7 0%, #FCCA33 100%, #000000 100%) 0 0 no-repeat padding-box;

      @media screen and (max-width: 1440px) {
        height: 500px;
        padding: 50px;
      }

      @media screen and (max-width: 991px) {
        height: 350px;
        padding: 35px;
        margin-bottom: 30px;
      }

      h3 {
        margin-bottom: 25px;
      }

      p {
        margin-bottom: 0;
      }
    }
  }

  .fees_token_section {
    margin-top: 150px;
    padding: 125px 75px;

    @media screen and (max-width: 1440px) {
      margin-top: 120px;
      padding: 80px 50px;
    }

    @media screen and (max-width: 991px) {
      margin-top: 80px;
      border-radius: 20px;
      padding: 45px 25px;
    }

    .left_side {
      display: flex;
      align-items: flex-start;

      @media screen and (max-width: 991px) {
        flex-direction: column;
      }

      .icon {
        margin-top: 12px;
        margin-right: 30px;

        @media screen and (max-width: 991px) {
          margin-bottom: 30px;
        }

        img {
          width: 105px;
        }
      }

      .title {
        font-size: 75px;
        line-height: 90px;
        color: $dark;
        font-family: $font_bangers;
        margin-bottom: 55px;

        @media screen and (max-width: 1440px) {
          font-size: 55px;
          margin-bottom: 45px;
          line-height: 1.2;
        }

        @media screen and (max-width: 991px) {
          font-size: 40px;
          margin-bottom: 35px;
        }

      }
    }

    .right_side {
      margin-left: 150px;
      margin-right: 65px;

      @media screen and (max-width: 1440px) {
        margin-left: 80px;
        margin-right: 20px;

      }

      @media screen and (max-width: 991px) {
        margin: 10px 0 0;
      }

      .block1 {
        padding-bottom: 30px;
        margin-bottom: 70px;
        border-bottom: 1px solid $orange;

        @media screen and (max-width: 991px) {
          padding-bottom: 10px;
          margin-bottom: 25px;
        }

        p {
          margin-bottom: 0;
        }
      }

      .block2 {
        margin-bottom: 35px;

        @media screen and (max-width: 991px) {
          margin-bottom: 20px;
        }

        h3 {
          font-size: 45px;
          margin-bottom: 10px;

          @media screen and (max-width: 1440px) {
            font-size: 35px;
          }

          @media screen and (max-width: 991px) {
            font-size: 25px;
          }
        }

        p {
          margin-bottom: 0;
        }
      }
    }
  }

  .social_section_wrap {
    background: url("./assets/images/hero-bg-2.svg") no-repeat;
    background-position: -30% 0;
    background-size: 40%;
  }

  .social_section {
    padding-top: 120px;

    @media screen and (max-width: 1440px) {
      padding-top: 80px;
    }

    @media screen and (max-width: 991px) {
      padding-top: 80px;
    }

    .social_block {
      background: transparent linear-gradient(149deg, #FFF0D7 0%, #FCCA33 100%) 0 0 no-repeat padding-box;
      border-radius: 50px;
      padding: 50px 55px;
      display: flex;
      flex-direction: column;
      height: 100%;

      @media screen and (max-width: 1440px) {
        border-radius: 35px;
      }

      @media screen and (max-width: 991px) {
        border-radius: 20px;
        padding: 40px 25px 25px;
        margin-bottom: 40px;
        height: auto;
      }

      h3 {
        margin-bottom: 25px;
      }

      .title {
        margin-bottom: 120px;

        @media screen and (max-width: 991px) {
          margin-bottom: 60px;
        }
      }

    }
  }

  .custom_tabbing {
    background: url("./assets/images/hero-bg-2.svg") no-repeat;
    background-position: 120% bottom;
    padding-top: 250px;
    padding-bottom: 350px;
    position: relative;

    @media screen and (max-width: 1440px) {
      padding-top: 120px;
      padding-bottom: 220px;
    }

    @media screen and (max-width: 1366px) {
      padding-bottom: 120px;
    }

    @media screen and (max-width: 991px) {
      padding-top: 80px;
      padding-bottom: 80px;
      background-size: 60%;
    }

    .round_image {
      position: absolute;
      top: 315px;
      left: -470px;
      z-index: -1;
      width: 2100px;

      @media screen and (max-width: 1440px) {
        top: 185px;
        width: 1720px;

        #dots,
        #line {
          width: 1720px;
        }
      }

      @media screen and (max-width: 1366px) {
        display: none;
      }
    }

    .tab_title {
      @media screen and (max-width: 1366px) {
        display: flex;
        justify-content: center;
      }

      .nav-item:nth-child(1) {
        left: 15%;
      }

      .nav-item:nth-child(2) {
        left: 82%;
        top: 45%;
      }

      .nav-item:nth-child(3) {
        left: 35%;
        bottom: 145px;

        @media screen and (max-width: 1440px) {
          bottom: 15px;
        }
      }
    }

    .nav {
      .nav-item {
        position: absolute;
        cursor: pointer;

        @media screen and (max-width: 1366px) {
          position: static;
        }

        .nav-link {
          background: transparent linear-gradient(180deg, #FFF0DF 0%, #FFE7D7 52%, #FFDFCF 100%) 0 0 no-repeat padding-box;
          border: 10px solid transparent;
          border-radius: 100%;
          padding: 10px;

          @media screen and (max-width: 991px) {
            padding: 8px;
          }

          &.active {
            span {
              background: transparent linear-gradient(137deg, #FFF0D7 0%, #FCCA33 100%) 0 0 no-repeat padding-box;
            }
          }

          span {
            border-radius: 100%;
            font-size: 45px;
            line-height: 50px;
            color: $dark;
            font-family: $font_bangers;
            width: 90px;
            height: 90px;
            background: #fff;
            display: flex;
            align-items: center;
            justify-content: center;

            @media screen and (max-width: 1440px) {
              width: 70px;
              height: 70px;
              font-size: 40px;
            }

            @media screen and (max-width: 991px) {
              width: 50px;
              height: 50px;
              font-size: 32px;
            }
          }
        }
      }
    }

    .tab_content {
      max-width: 650px;
      min-height: 690px;
      margin-top: 180px;
      margin-left: 25%;
      background: transparent linear-gradient(180deg, #FFF0DF 0%, #FFE7D7 52%, #FFDFCF 100%) 0 0 no-repeat padding-box;
      border-radius: 50px;
      padding: 50px;

      @media screen and (max-width: 1440px) {
        border-radius: 35px;
        padding: 35px;
      }

      @media screen and (max-width: 1366px) {
        min-height: unset;
        margin: 80px auto 0;
      }

      @media screen and (max-width: 991px) {
        margin: 40px auto 0;
        border-radius: 20px;
      }

      h4 {
        margin-bottom: 40px;
        font-size: 32px !important;
      }

      ul {
        list-style: none;
        padding-left: 0;

        li {
          font-size: 25px;
          line-height: 30px;
          min-height: 50px;
          color: #000;
          font-family: $font_silkscreen;
          margin-bottom: 30px;
          padding-left: 70px;
          background: url("./assets/images/bullet.svg") no-repeat left 0;
          display: flex;
          align-items: center;

          &:last-child {
            margin-bottom: 0;
          }

          @media screen and (max-width: 991px) {
            background-size: 35px;
            min-height: 35px;
            padding-left: 60px;
          }

          a {
            text-decoration: underline;
          }

          span {
            color: #000;
            margin-top: 10px;
            display: block;
            font-size: 18px;
            line-height: 25px;
            font-family: $font_barlow;
          }
        }
      }
    }
  }

  .presale_section {
    padding: 140px;
    border-radius: 50px;
    background: transparent linear-gradient(135deg, #FFEED1 0%, #FCCA33 100%) 0 0 no-repeat padding-box;

    @media screen and (max-width: 1440px) {
      padding: 80px;
      border-radius: 35px;
    }

    @media screen and (max-width: 991px) {
      padding: 40px;
      border-radius: 20px;
    }

    .presale_content {
      h2 {
        margin-bottom: 30px;
      }

      .btn_white {
        display: inline-block;
        margin-top: 50px;
      }
    }

    .presale_media {
      margin-left: 120px;

      @media screen and (max-width: 991px) {
        margin-left: 0;
        margin-top: 50px;
      }

      img {
        width: 100%;
      }
    }
  }
}

footer {
  background: url("./assets/images/footer-border.svg") no-repeat center top, url("./assets/images/hero-bg-2.svg") no-repeat -30% 0;
  margin-top: 120px;

  @media screen and (max-width: 1200px) {
    background: url("./assets/images/footer-border.svg") no-repeat center top;
  }

  @media screen and (max-width: 991px) {
    background: url("./assets/images/footer-border.svg") no-repeat center top;
    margin-top: 80px;
  }

  .footer_logo {
    margin-top: 100px;
    margin-bottom: 50px;

    @media screen and (max-width: 991px) {
      margin-top: 60px;
      margin-bottom: 30px;
    }
  }

  h2 {
    margin-bottom: 30px;

    @media screen and (max-width: 991px) {
      margin-bottom: 20px;
    }
  }

  .social_links {
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    z-index: 9;

    a {
      margin: 0;
    }

    :nth-child(2) {
      margin: 0 18px -102px;

      @media screen and (max-width: 991px) {
        margin: 0 18px 0;
      }
    }
  }

  .menu_wrap {
    display: flex;
    height: 430px;
    padding: 20px;

    @media screen and (max-width: 1600px) {
      height: 360px;
    }

    @media screen and (max-width: 991px) {
      height: auto;
      margin: 40px 0;
      justify-content: center;
    }

    &.menu_wrap2 {
      justify-content: flex-end; //re
      // justify-content: center; //new

      @media screen and (max-width: 991px) {
        justify-content: center;
      }
    }

    .menu_link {
      width: 240px;
      height: 240px;
      border-radius: 100%;
      border: 1px solid $orange;
      display: flex;
      align-items: center;
      justify-content: center;
      position: relative;
      flex: none;
      cursor: pointer;

      @media screen and (max-width: 1600px) {
        width: 180px;
        height: 180px;
      }

      @media screen and (max-width: 1200px) {
        width: 150px;
        height: 150px;
      }

      &.contact {
        align-self: end; //re
        margin: 0 -80px 30px 0; //re
        // align-items: center; // new
        // margin: 0; // new

        @media screen and (max-width: 1440px) {
          margin: 0 -70px -30px 0;
        }

        @media screen and (max-width: 991px) {
          align-self: unset;
          margin: 0;
        }
      }

      &.explorer {
        align-self: end;
        margin: 0 0 30px -80px;

        @media screen and (max-width: 1440px) {
          margin: 0 0 50px -60px;
        }

        @media screen and (max-width: 991px) {
          align-self: unset;
          margin: 0;
        }
      }

      .text {
        font-size: 16px;
        line-height: 20px;
        color: $dark;
        font-family: $font_silkscreen;
        text-transform: uppercase;
        text-align: center;

        @media screen and (max-width: 1200px) {
          font-size: 14px;
        }
      }

      .ball {
        width: 100%;
        height: 100%;
        position: absolute;

        &:hover {
          animation: rotation 6s linear infinite;
        }

        @keyframes rotation {
          0% {
            transform: rotate(0deg);
          }

          100% {
            transform: rotate(360deg);
          }
        }

        &::before {
          content: '';
          width: 25px;
          height: 25px;
          background: $orange;
          border-radius: 100%;
          position: absolute;
          top: 55px;

          @media screen and (max-width: 1600px) {
            width: 18px;
            height: 18px;
            top: 18px;
            left: 18px;
          }

          @media screen and (max-width: 1200px) {
            width: 13px;
            height: 13px;
            top: 15px;
            left: 15px;
          }
        }
      }
    }
  }

  .footer_bottom {
    @media screen and (min-width: 992px) {
      margin-top: -60px;
    }

    .leftf {
      p {
        font-size: 18px;

        @media screen and (max-width: 991px) {
          text-align: center;
        }
      }
    }

    .rightf {
      ul {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        list-style: none;
        padding-left: 0;

        @media screen and (max-width: 991px) {
          justify-content: center;
        }

        li {
          a {
            font-size: 18px;
            color: #000;
            text-decoration: none;
            margin: 0 10px;
          }
        }
      }
    }
  }
}

.platforms-artwork {
  grid-column: 1 / -1;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 150px 0;

  @media screen and (max-width: 1440px) {
    margin: 80px 0;
  }

  @media screen and (max-width: 991px) {
    margin: 0;
  }

  // @include media-1400 {
  //     transform: translateY(-80px);
  // }

  // @include media-1200 {
  //     transform: translateY(-60px);
  // }

  // @include media-1000 {
  //     transform: translateY(0);
  // }

  .wrapper {
    height: 1000px;
    width: 1000px;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;

    @media screen and (max-width: 1440px) {
      height: 700px;
      width: 700px;
    }

    @media screen and (max-width: 1200px) {
      height: 500px;
      width: 500px;
    }

    @media screen and (max-width: 991px) {
      height: 70vw;
      width: 70vw;
      margin-top: 40px;
      margin-bottom: 70px;
    }

    .rings {
      position: absolute;
      display: flex;
      align-items: center;
      justify-content: center;
      height: 100%;
      width: 100%;
      overflow: hidden;

      .ring {
        z-index: -1;
        --size: 75%;
        border-radius: 50%;
        position: absolute;
        width: var(--size);
        height: var(--size);
        animation: ring-anim 60s linear infinite forwards;
        display: flex;
        align-items: center;
        justify-content: center;
      }

      .middle-ring {
        --size: 100%;
        animation-direction: reverse;
        animation-duration: 90s;
      }

      .outer-ring {
        --size: 100%;
        animation-duration: 120s;
      }

      @keyframes ring-anim {
        100% {
          transform: rotate(360deg);
        }
      }
    }

    .tools {
      position: relative;
      z-index: 1;
      width: 100%;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;

      .tool {
        user-select: none;
        display: flex;
        align-items: center;
        justify-content: center;
        position: absolute;
        border-radius: 500px;
        box-shadow: 0 10px 20px 0 rgba(240, 83, 63, 0.2);
        will-change: transform, opacity;
        transition: all 1s cubic-bezier(0, -0.01, 0, 1.31);

        img {
          height: 50px;
          width: 50px;
        }

        &:hover {
          box-shadow: 0 30px 50px 0 rgba(240, 83, 63, 0.1);
          transform: scale(1.1);
        }

        &.dogepad {
          display: flex;
          flex-direction: column;
          box-shadow: none;
          padding: 0;
          transform: scale(1);

          h2 {
            @media screen and (max-width: 991px) {
              font-size: 30px;
            }
          }

          img {
            flex: none;
            width: 300px;
            height: 300px;

            @media screen and (max-width: 1440px) {
              width: 150px;
              height: 150px;
            }

            @media screen and (max-width: 991px) {
              width: 80px;
              height: 80px;
            }
          }

          h2 {
            margin-top: 25px;
          }

          &:hover {
            box-shadow: none;
            transform: scale(1);
          }

          // @include media-800 {
          //     height: 55px;
          //     width: 55px;
          // }
        }

        &.stellar {
          top: 15%;
          left: 20%;

          img {
            width: 130px;
            height: 130px;

            @media screen and (max-width: 1440px) {
              width: 65px;
              height: 65px;
            }

            @media screen and (max-width: 991px) {
              width: 40px;
              height: 40px;
            }

          }

          // @include media-800 {
          //     bottom: -20px;
          // }
        }

        &.hedera {
          left: 32%;
          top: 0;

          img {
            width: 50px;
            height: 50px;

            @media screen and (max-width: 991px) {
              width: 20px;
              height: 20px;
            }
          }
        }

        &.hyperledger {
          bottom: 9%;
          left: 45%;

          img {
            width: 70px;
            height: 70px;

            @media screen and (max-width: 991px) {
              width: 30px;
              height: 30px;
            }

          }

        }

        &.nem {
          top: 15%;
          right: 5%;

          img {
            width: 100px;
            height: 100px;

            @media screen and (max-width: 1440px) {
              width: 75px;
              height: 75px;
            }

            @media screen and (max-width: 991px) {
              width: 40px;
              height: 40px;
            }

          }
        }

        &.corda {
          top: 25%;
          left: 30%;

          // @include media-800 {
          //     top: 5%;
          // }
        }

        &.bigchain {
          bottom: 30%;
          right: 25%;
        }

        &.multichain {
          top: 40%;
          right: 7%;

          img {
            width: 120px;
            height: 120px;

            @media screen and (max-width: 1440px) {
              width: 80px;
              height: 80px;
            }

            @media screen and (max-width: 991px) {
              width: 45px;
              height: 45px;
            }

          }

        }

        &.eos {
          bottom: 35%;
          left: 25%;
        }

        &.credits {
          left: 5%;
          bottom: 10%;

          img {
            width: 130px;
            height: 130px;

            @media screen and (max-width: 1440px) {
              width: 85px;
              height: 85px;
            }

            @media screen and (max-width: 991px) {
              width: 55px;
              height: 55px;
            }
          }
        }

        &.ethereum {
          bottom: 7%;
          right: 15%;

          img {
            width: 80px;
            height: 80px;

            @media screen and (max-width: 1440px) {
              width: 50px;
              height: 50px;
            }

            @media screen and (max-width: 991px) {
              width: 30px;
              height: 30px;
            }
          }
        }
      }
    }
  }
}

.mo_cntr_mt {
  @media screen and (max-width: 991px) {
    text-align: center;
    margin-top: 50px;
  }
}

.all_campaigns {
  .hero_banner {
    .leftside {
      display: flex;
      align-items: flex-start;
      flex-direction: column;
      justify-content: center;

      p {
        font-family: $font_silkscreen;
        margin-bottom: 0;
      }
    }
  }

  .custom_tab {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;

    .btn_border {
      margin: 0 15px 30px;
    }
  }

  .active_campaign {
    background: transparent linear-gradient(122deg, #FFF0D7 0%, #FCCA33 100%) 0 0 no-repeat padding-box;
    @include border-radius(50px);
    padding: 80px 60px;
  }

  .other_campaign_wrap {
    margin-top: 110px;

    .other_campaign {
      margin-top: 30px;
      background: transparent linear-gradient(142deg, #FFF0D7 0%, #FCCA33 100%) 0 0 no-repeat padding-box;
      @include border-radius(50px);
      padding: 65px 60px;

      .row1 {
        .leftside {
          padding-right: 20px;

          .btn_custom {
            margin-top: 25px;
          }

          p {
            margin-bottom: 0;
          }
        }

        hr {
          margin-top: 25px;
          background: transparent linear-gradient(90deg, #FDDF8D 0%, #FCCA33 49%, #FEE8B5 100%) 0 0 no-repeat padding-box;
          height: 2px;
          width: 100%;
          opacity: 1 !important;
        }
      }

      .inner_card {
        margin-top: 50px;
      }

    }
  }
}

.inner_card {
  h3 {
    margin-bottom: 0;
  }

  p {
    font-family: $font_silkscreen;
    margin-bottom: 15px;
  }
}

.breadcrumb {
  justify-content: center;
  margin-bottom: 40px;

  a {
    font-size: 18px;
    line-height: 36px;
    color: #000;
    font-family: $font_barlow;
    text-decoration: none;
    margin: 0 15px;

    &.active {
      text-decoration: underline;
    }
  }
}

.pinkbox_round {
  padding: 90px;
  margin-top: 70px;
  background: transparent linear-gradient(145deg, #FFEDD9 0%, #FFE7D7 52%, #FFDFCF 100%) 0 0 no-repeat padding-box;
  border-radius: 50px;

  @media screen and (max-width: 1440px) {
    padding: 65px;
    margin-top: 65px;
    border-radius: 35px;
  }

  @media screen and (max-width: 991px) {
    padding: 40px;
    margin-top: 40px;
    border-radius: 20px;
  }

  h3 {
    margin-bottom: 70px;

    @media screen and (max-width: 1440px) {
      margin-bottom: 50px;
    }

    @media screen and (max-width: 991px) {
      margin-bottom: 35px;
    }
  }

  .upload_logo_wrap {
    background: #FFF4EB;
    border: 1px solid #CECECE;
    @include border-radius(10px);
    height: 230px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;

    input[type="file"] {
      display: none;
    }

    label {
      display: flex;
      align-items: center;
      flex-direction: column;
      margin-bottom: 0;

      p {
        text-transform: uppercase;
        font-family: $font_silkscreen;
        color: #D4D4D4;
        margin-top: 25px;
        margin-bottom: 0;
      }
    }
  }

  .btn_wrap {
    button {
      margin: 0 15px;
    }

    .btn_black {
      margin-top: 30px;
    }
  }
}

.create_campaign {
  margin-bottom: 120px;

  .tab_title {
    margin-top: 70px;
    display: flex;
    justify-content: center;

    .nav-link {
      border-radius: 100%;
      background: transparent;
      padding: 0 25px;


      &.active {
        span {
          background: transparent linear-gradient(137deg, #FFF0D7 0%, #FCCA33 100%) 0 0 no-repeat padding-box;
          border: 1px solid transparent;
        }
      }

      span {
        border-radius: 100%;
        font-size: 16px;
        line-height: 20px;
        color: #000;
        font-family: $font_silkscreen;
        width: 40px;
        height: 40px;
        background: transparent;
        display: flex;
        align-items: center;
        justify-content: center;
        border: 1px solid #707070;
        cursor: pointer;
      }
    }
  }
}

.token_data_card {
  background: transparent linear-gradient(103deg, #FFF0D7 0%, #FCCA33 100%) 0% 0% no-repeat padding-box;
  @include border-radius(10px);
  padding: 28px 50px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 10px;
  margin-bottom: 40px;

  .inner_card {
    p {
      margin-bottom: 15px;
    }

    h3 {
      margin-bottom: 0;
    }
  }
}

.coming_soon {
  padding: 50px 110px;
  @include border-radius(30px);
  margin: 120px auto;
  text-align: center;
  background: url("./assets/images/bitcoin.jpg") no-repeat;
  background-size: cover;
  max-width: 1200px;
  height: 750px;
}

.contact_us {
  background: url("./assets/images/hero-bg-2.svg") no-repeat;
  background-position: 100% 120px;
  background-size: auto;

  @media screen and (max-width: 1200px) {
    background-position: 350px 140px;
    background-size: 70%;
  }
}

.form_group {
  margin-bottom: 40px;

  @media screen and (max-width: 1440px) {
    margin-bottom: 30px;
  }

  @media screen and (max-width: 991px) {
    margin-bottom: 20px;
  }

  label {
    margin-bottom: 25px;

    @media screen and (max-width: 1440px) {
      margin-bottom: 15px;
    }

    @media screen and (max-width: 991px) {
      margin-bottom: 10px;
    }
  }

  textarea {
    height: 170px !important;

    @media screen and (max-width: 1440px) {
      height: 130px !important;
    }

    @media screen and (max-width: 991px) {
      height: 100px !important;
      padding: 8px 15px !important;
    }
  }

  .form_control {
    background: #FFF4EB;
    border: 1px solid #CECECE;
    @include border-radius(10px);
    height: 80px;
    width: 100%;
    color: #fff;
    padding: 24px 30px;
    font-size: 25px;
    line-height: 36px;
    color: #000;
    font-family: $font_silkscreen;

    @media screen and (max-width: 1440px) {
      padding: 15px 20px;
      height: 65px;
    }

    @media screen and (max-width: 991px) {
      padding: 15px;
      font-size: 20px;
      @include border-radius(5px);
      height: 50px;
    }

    &:focus {
      background: #fff;
      box-shadow: none;
      outline: none;
      border: 1px solid #707070;
    }
  }

  .error_custom {
    font-family: $font_barlow;
    font-size: 20px;
    font-weight: 500;
    color: #f00;
    margin: 3px 0 0 2px;

    @media screen and (max-width: 991px) {
      font-size: 16px;
    }
  }
}

.custom_modal {
  .modal-dialog {
    max-width: 780px;

    .modal-content {
      background: transparent linear-gradient(155deg, #FFEDD9 0%, #FFE7D7 52%, #FFDFCF 100%) 0% 0% no-repeat padding-box;
      @include border-radius(50px);
      padding: 80px;
      position: relative;

      .modal-header {
        padding: 0;
        border-bottom: none;
        margin-bottom: 50px;

        .btn-close {
          position: absolute;
          top: 50px;
          right: 50px;
        }
      }

      .modal-body {
        padding: 0;

        label {
          font-size: 25px;
        }

        button {
          margin-top: 20px;
        }

        .row_1 {
          margin-top: 40px;

          .btn_wrap {
            display: flex;
            align-items: center;
            justify-content: flex-end;
          }
        }
      }
    }
  }
}

.pool_page {
  .pinkbox_round {
    .row_1 {
      display: flex;
      align-items: center;

      .icon {
        img {
          width: 65px;
          height: auto;
        }
      }

      .inner_card {
        margin: 0 65px;

        h3 {
          margin-bottom: 0;
        }
      }

      .btn_wrap {}
    }
  }
}

.accordion {
  margin-top: 70px;

  .accordion-item {
    margin-bottom: 30px;
    padding: 46px 50px;
    background: transparent linear-gradient(145deg, #FFEDD9 0%, #FFE7D7 52%, #FFDFCF 100%) 0 0 no-repeat padding-box;
    border-radius: 50px;
    border: none;

    @media screen and (max-width: 1440px) {
      padding: 35px;
      border-radius: 35px;
    }

    @media screen and (max-width: 991px) {
      padding: 25px;
      border-radius: 20px;
    }

    h2.accordion-header {
      .accordion-button {
        @extend h3;
        background: transparent;
        padding: 0;
        margin: 0;
        box-shadow: none;
        border: none;

        &::after {
          background: url("./assets/images/arrow-down.svg") no-repeat;
          background-size: cover;
          width: 40px;
          height: 40px;
        }
      }
    }

    .accordion-collapse {
      .accordion-body {
        padding: 0;
        margin-top: 25px;
        a {
          color: $orange;
        }
      }
    }
  }
}